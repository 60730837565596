@tailwind base;
@tailwind components;
@tailwind utilities;

.button {
  @apply flex items-center justify-center gap-2 text-white border-2 border-solid bg-primary-700 border-primary-700 hover:bg-primary-800 hover:border-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-md px-4 py-2 focus:outline-none;
}

.button[disabled] {
  @apply bg-gray-300 border-gray-300 cursor-not-allowed hover:bg-gray-300 hover:border-gray-300 text-gray-500 hover:text-gray-500;
}

.button-outline[disabled] {
  @apply bg-gray-300 border-gray-300 cursor-not-allowed hover:bg-gray-300 hover:border-gray-300 text-gray-500 hover:text-gray-500;
}

.button-primary {
  @apply text-white bg-primary-700 border-primary-700 hover:bg-primary-800 hover:border-primary-800 focus:ring-primary-300;
}

.button-secondary {
  @apply text-white bg-secondary-700 border-secondary-700 hover:bg-secondary-800 hover:border-secondary-800 focus:ring-secondary-300;
}

.button-accent {
  @apply text-white bg-accent-700 border-accent-700 hover:bg-accent-800 hover:border-accent-800 focus:ring-accent-300;
}

.button-danger {
  @apply text-white bg-accent-700 border-accent-700 hover:bg-accent-800 hover:border-accent-800 focus:ring-accent-300;
}

.button-faded {
  @apply text-white bg-gray-700 border-gray-700 hover:bg-gray-800 hover:border-gray-800 focus:ring-gray-300;
}

.button-outline {
  @apply flex items-center gap-2 border-2 border-solid rounded-md hover:text-white border-primary-400 hover:border-primary-700 hover:bg-primary-700 transition-all focus:ring-4 focus:ring-primary-300 font-medium px-4 py-2 focus:outline-none;
}

.button-outline-primary {
  @apply border-primary-400 hover:border-primary-700 hover:bg-primary-700 focus:ring-primary-300;
}

.button-outline-secondary {
  @apply border-secondary hover:border-secondary hover:bg-secondary focus:ring-secondary-100;
}

.button-outline-accent {
  @apply border-accent-400 hover:border-accent hover:bg-accent focus:ring-accent-200;
}

.button-outline-danger {
  @apply border-red-400 hover:border-red-400 hover:bg-red-400 focus:ring-red-200;
}

.button-outline-faded {
  @apply border-gray-400 hover:border-gray-400 hover:bg-gray-400 focus:ring-gray-200;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
