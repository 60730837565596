@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("/assets/fonts/Lato/Lato-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Peinture";
  src: url("/assets/fonts/Peinture/Peinture-Fraiche.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
