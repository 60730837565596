@use "@angular/material" as mat;
@import "./theme";
@import "./typography";
@import "./tailwind";
@import "./material-timepicker";

@include mat.core();
html {
  @include mat.all-component-themes($theme);
}

body {
  margin: 0;
  box-sizing: border-box;
}

// Styling to make sure that the page content inserted after the router outlet is always takes up the full remaining screen height.
#app-root > router-outlet + * {
  flex-grow: 1;
}

// TODO - Remove this once the issue is fixed in the Angular Material library.
// Issue via https://github.com/angular/components/issues/13419
app-faq-entry mat-expansion-panel:not(.mat-expanded),
app-faq-entry mat-expansion-panel:not(.mat-expansion-panel-spacing) {
  border-radius: 0 !important;
}

app-faq-entry:first-child mat-expansion-panel {
  border-top-right-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}

app-faq-entry:last-child mat-expansion-panel {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

table.loading {
  td {
    @apply relative;
  }

  td::before {
    @apply block absolute top-0 left-0 right-0 bottom-0;
    content: "";
    background-color: white;
  }

  td::after {
    @apply block absolute h-[1rem] left-[10%] right-[10%] animate-pulse top-[50%] -translate-y-1/2 content-[""] bg-gray-300;
  }
}
